<template>
  <base-layout>
    <h1 class="title">Secure Link</h1>
    <validation-observer v-slot="{ invalid }">
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">URL</label>
          <input
              class="input"
              v-model="url"
              name="URL"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">SMS Content</label>
          <input
              class="input"
              v-model="smsContent"
              name="SMS Content"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Email Subject</label>
          <input
              class="input"
              v-model="emailSubject"
              name="Email Subject"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:1000"
          class="field"
      >
        <div class="control">
          <label class="label">Email Content</label>
          <textarea
              class="textarea"
              v-model="emailContent"
              name="Email Content"
          ></textarea>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <div class="field">
        <div class="buttons">
          <button
              :disabled="invalid"
              class="button is-success"
              @click="saveSecureLinkConfig"
          >
            Save
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
        <progress-bar />
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {max} from "vee-validate/dist/rules";
import ProgressBar from "@/components/ProgressBar.vue";

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    BaseLayout,
    ValidationObserver,
    ValidationProvider,
    ProgressBar
  },
  data() {
    return {
      smsContent: null,
      emailSubject: null,
      emailContent: null,
      url: null
    };
  },
  methods: {
    async saveSecureLinkConfig() {
      try {
        this.$store.commit("SHOW_PROGRESS");
        this.$store.commit("HIDE_NOTIFICATION");
        const payload = {
          smsContent: this.smsContent,
          emailSubject: this.emailSubject,
          emailContent: this.emailContent,
          url: this.url
        }
        await this.$store.dispatch("saveSecureLinkConfig", payload);
        this.$store.commit("SHOW_NOTIFICATION", {
          message: `Secure Link config data saved`,
          type: "success"
        });
      } catch(e) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: `Error saving Secure Link config; ${e}`,
          type: "error"
        });
      } finally {
        this.$store.commit("HIDE_PROGRESS");
      }
    },
    async revert() {
      await this.$store.dispatch("fetchSecureLinkConfig");
      this.loadView();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Secure Link config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    },
    loadView() {
      const secureLink = this.$store.state.secureLinkConfig;
      if( secureLink ) {
        this.smsContent = secureLink.smsContent;
        this.emailSubject = secureLink.emailSubject;
        this.emailContent = secureLink.emailContent;
        this.url = secureLink.url;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.$store.dispatch("fetchPaymentPortalGlobalConfig");
        await vm.$store.dispatch("fetchSecureLinkConfig");
        vm.loadView();
      } catch (e) {
        vm.$store.commit("SHOW_NOTIFICATION", `Error loading Secure Link config; ${e}`);
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  }
};
</script>