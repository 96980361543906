var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('h1',{staticClass:"title"},[_vm._v("Receipt Comms")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"panel"},[_c('h2',{staticClass:"panel-heading"},[_vm._v("SMS")]),_c('div',{staticClass:"panel-block"},[_c('validation-provider',{staticClass:"control",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"label"},[_vm._v("SMS Content")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.smsContent),expression:"smsContent"}],staticClass:"textarea",attrs:{"name":"SMS Content"},domProps:{"value":(_vm.smsContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.smsContent=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"panel-block"},[_c('div',{staticClass:"dict-wrapper"},[_c('div',{staticClass:"dict-title"},[_vm._v("Click to copy dictionary")]),_c('div',{staticClass:"dict-body"},_vm._l((_vm.lstDictionary),function(inst){return _c('div',{key:inst,staticClass:"dict-item",on:{"click":function($event){return _vm.copyDict(inst)}}},[_vm._v(_vm._s(inst))])}),0)])])]),_c('div',{staticClass:"panel"},[_c('h2',{staticClass:"panel-heading"},[_vm._v("Email")]),_c('div',{staticClass:"panel-block"},[_c('validation-provider',{staticClass:"control",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"label"},[_vm._v("Email From")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailFrom),expression:"emailFrom"}],staticClass:"input",attrs:{"name":"Email From"},domProps:{"value":(_vm.emailFrom)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailFrom=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"panel-block"},[_c('validation-provider',{staticClass:"control",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"label"},[_vm._v("Email Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailSubject),expression:"emailSubject"}],staticClass:"input",attrs:{"name":"Email Subject"},domProps:{"value":(_vm.emailSubject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailSubject=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"panel-block"},[_c('validation-provider',{staticClass:"control",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('label',{staticClass:"label"},[_vm._v("Email Content")]),_c('editor',{attrs:{"name":"Email Content","api-key":_vm.tinyMceApi,"init":{
              plugins: 'lists link image table code help wordcount'
            }},model:{value:(_vm.emailContent),callback:function ($$v) {_vm.emailContent=$$v},expression:"emailContent"}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"panel-block"},[_c('div',{staticClass:"dict-wrapper"},[_c('div',{staticClass:"dict-title"},[_vm._v("Click to copy dictionary")]),_c('div',{staticClass:"dict-body"},_vm._l((_vm.lstDictionary),function(inst){return _c('div',{key:inst,staticClass:"dict-item",on:{"click":function($event){return _vm.copyDict(inst)}}},[_vm._v(_vm._s(inst))])}),0)]),_c('span',{staticClass:"has-text-danger",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.emailError))])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.saveReceiptCommsConfig}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])]),_c('progress-bar')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }