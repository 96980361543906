<template>
  <base-layout>
    <h1 class="title">Hosted Payment Page</h1>
    <validation-observer v-slot="{ invalid }">
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Document Title</label>
          <input
              class="input"
              v-model="documentTitle"
              name="Document Title"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Header</label>
          <input
              class="input"
              v-model="header"
              name="Header"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          class="field"
      >
        <div class="control">
          <label class="label">Header Color</label>
          <input
              type="color"
              class="input"
              v-model="headerColor"
              name="Header Color"
          />
        </div>
      </validation-provider>

      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Logo URL</label>
          <input
              class="input"
              v-model="logoURL"
              name="Logo URL"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <file-upload
          inputName="file_logoURL"
          inputRef="file_logoURL"
          @fileUploaded="updateLogoURL"
      />

      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Logo HREF</label>
          <input
              class="input"
              v-model="logoHREF"
              name="Logo HREF"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Secondary Banner</label>
          <input
              class="input"
              v-model="secondaryBanner"
              name="Secondary Banner"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          class="field"
      >
        <div class="control">
          <label class="label">Secondary Banner Color</label>
          <input
              type="color"
              class="input"
              v-model="secondaryBannerColor"
              name="Secondary Banner Color"
          />
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Footer</label>
          <input
              class="input"
              v-model="footer"
              name="Footer"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          class="field"
      >
        <div class="control">
          <label class="label">Footer Color</label>
          <input
              type="color"
              class="input"
              v-model="footerColor"
              name="Footer Color"
          />
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Footer 2</label>
          <input
              class="input"
              v-model="footer2"
              name="Footer 2"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Favicon URL</label>
          <input
              class="input"
              v-model="faviconURL"
              name="Favicon URL"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <file-upload
          inputName="file_faviconURL"
          inputRef="file_faviconURL"
          @fileUploaded="updateFaviconURL"
      />

      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:1000"
          class="field"
      >
        <div class="control">
          <label class="label">General Contact Message For Help</label>
          <textarea
              class="textarea"
              v-model="generalContactMessage"
              name="General Contact Message"
          ></textarea>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">Fee Annotation</label>
          <input
              class="input"
              v-model="feeAnnotation"
              name="Fee Annotation"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:1000"
          class="field"
      >
        <div class="control">
          <label class="label">Success Message</label>
          <textarea
              class="textarea"
              v-model="successMessage"
              name="Success Message"
          ></textarea>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <div class="field">
        <div class="buttons">
          <button
              :disabled="invalid"
              class="button is-success"
              @click="savePaymentPortalGlobalConfig"
          >
            Save
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
        <progress-bar />
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {max} from "vee-validate/dist/rules";
import ProgressBar from "@/components/ProgressBar.vue";
import FileUpload from "@/components/FileUpload.vue";

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    BaseLayout,
    ValidationObserver,
    ValidationProvider,
    ProgressBar,
    FileUpload
  },
  data() {
    return {
      documentTitle: null,
      header: null,
      logoURL: null,
      logoHREF: null,
      secondaryBanner: null,
      footer: null,
      footer2: null,
      headerColor: null,
      footerColor: null,
      secondaryBannerColor: null,
      faviconURL: null,
      generalContactMessage: null,
      feeAnnotation: null,
      successMessage: null
    };
  },
  methods: {
    async savePaymentPortalGlobalConfig() {
      try {
        this.$store.commit("SHOW_PROGRESS");
        this.$store.commit("HIDE_NOTIFICATION");
        const payload = {
          documentTitle: this.documentTitle,
          header: this.header,
          logoURL: this.logoURL,
          logoHREF: this.logoHREF,
          secondaryBanner: this.secondaryBanner,
          footer: this.footer,
          footer2: this.footer2,
          headerColor: this.headerColor,
          secondaryBannerColor: this.secondaryBannerColor,
          faviconURL: this.faviconURL,
          generalContactMessage: this.generalContactMessage,
          feeAnnotation: this.feeAnnotation,
          successMessage: this.successMessage
        }
        await this.$store.dispatch("saveHostedPaymentPageConfig", payload);
        this.$store.commit("SHOW_NOTIFICATION", {
          message: `Hosted Payment Page config data saved`,
          type: "success"
        });
      } catch(e) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: `Error saving Hosted Payment Page config; ${e}`,
          type: "error"
        });
      } finally {
        this.$store.commit("HIDE_PROGRESS");
      }
    },
    async revert() {
      await this.$store.dispatch("fetchHostedPaymentPageConfig");
      this.loadView();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Hosted Payment Page config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    },
    loadView() {
      const hpp = this.$store.state.hppConfig;
      if( hpp ) {
        this.documentTitle = hpp.documentTitle;
        this.header = hpp.header;
        this.logoURL = hpp.logoURL;
        this.logoHREF = hpp.logoHREF;
        this.secondaryBanner = hpp.secondaryBanner;
        this.footer = hpp.footer;
        this.footer2 = hpp.footer2;
        this.headerColor = hpp.headerColor;
        this.footerColor = hpp.footerColor;
        this.secondaryBannerColor = hpp.secondaryBannerColor;
        this.faviconURL = hpp.faviconURL;
        this.generalContactMessage = hpp.generalContactMessage;
        this.feeAnnotation = hpp.feeAnnotation;
        this.successMessage = hpp.successMessage;
      }
    },
    updateLogoURL(fn) {
      this.logoURL = fn;
    },
    updateFaviconURL(fn) {
      this.faviconURL = fn;
    },
    fileName: fn => {
      if (fn) {
        let r = /([^/]+)$/g;
        let matches = fn.match(r);
        if (matches) {
          return matches[0];
        }
      }
      return "";
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.$store.dispatch("fetchPaymentPortalGlobalConfig");
        await vm.$store.dispatch("fetchHostedPaymentPageConfig");
        vm.loadView(vm);
      } catch (e) {
        vm.$store.commit("SHOW_NOTIFICATION", `Error loading Hosted Payment Page config; ${e}`);
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  }
};
</script>