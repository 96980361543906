<template>
  <base-layout>
    <h1 class="title">Receipt Comms</h1>
    <validation-observer v-slot="{ invalid }">
      <div class="panel">
        <h2 class="panel-heading">SMS</h2>
        <div class="panel-block">
          <validation-provider
              tag="div"
              v-slot="{ errors, failed }"
              rules="max:255"
              class="control"
          >
            <label class="label">SMS Content</label>
            <textarea
                class="textarea"
                v-model="smsContent"
                name="SMS Content"></textarea>
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="panel-block">
          <div class="dict-wrapper">
            <div class="dict-title">Click to copy dictionary</div>
            <div class="dict-body">
              <div v-for="inst in lstDictionary" :key="inst" class="dict-item" @click="copyDict(inst)">{{ inst }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel">
        <h2 class="panel-heading">Email</h2>
        <div class="panel-block">
          <validation-provider
              tag="div"
              v-slot="{ errors, failed }"
              rules="max:255"
              class="control"
          >
            <label class="label">Email From</label>
            <input
                class="input"
                v-model="emailFrom"
                name="Email From"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="panel-block">
          <validation-provider
              tag="div"
              v-slot="{ errors, failed }"
              rules="max:255"
              class="control"
          >
            <label class="label">Email Subject</label>
            <input
                class="input"
                v-model="emailSubject"
                name="Email Subject"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="panel-block">
          <validation-provider
              tag="div"
              rules="max:1000"
              class="control"
              v-slot="{ errors, failed }"
          >
              <label class="label">Email Content</label>
              <editor name="Email Content" :api-key="tinyMceApi" v-model="emailContent" :init="{
                plugins: 'lists link image table code help wordcount'
              }" />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="panel-block">
          <div class="dict-wrapper">
            <div class="dict-title">Click to copy dictionary</div>
            <div class="dict-body">
              <div v-for="inst in lstDictionary" :key="inst" class="dict-item" @click="copyDict(inst)">{{inst}}</div>
            </div>
          </div>
          <span role="alert" class="has-text-danger">{{ emailError }}</span>
        </div>
      </div>
      <div class="field">
        <div class="buttons">
          <button
              :disabled="invalid"
              class="button is-success"
              @click="saveReceiptCommsConfig"
          >
            Save
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
        <progress-bar />
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {max} from "vee-validate/dist/rules";
import ProgressBar from "@/components/ProgressBar.vue";
import Editor from '@tinymce/tinymce-vue'

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    BaseLayout,
    ValidationObserver,
    ValidationProvider,
    ProgressBar,
    Editor
  },
  data() {
    return {
      smsContent: null,
      emailFrom: null,
      emailSubject: null,
      emailContent: null,
      lstDictionary: ['${refNum}', '${paymentDateTime}', '${paymentAmount}', '${convFee}', '${totalPayment}'],
      emailError: null,
      tinyMceApi: process.env.VUE_APP_TINYMCE_KEY
    }
  },
  methods: {
    revert() {
      this.loadView()
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "Receipt Comms form reset",
        type: "success"
      })
      scrollTo(0,0)
    },
    async saveReceiptCommsConfig() {
      this.emailErrror = ''
      let emailValidation = this.validateEmailContent(this.emailContent);
      if (typeof emailValidation === 'string') {
        this.emailError = emailValidation;
        return;
      }
      if (!this.emailContent.trim().startsWith('<html')) {
        this.emailContent = '<html><body>' + this.emailContent + '</body></html>';
      }
      this.emailContent = this.emailContent.trim();

      this.$store.commit("HIDE_NOTIFICATION");
      this.$store.commit("SHOW_PROGRESS")

      try {
        const payload = {
          smsContent: this.smsContent,
          emailFrom: this.emailFrom,
          emailSubject: this.emailSubject,
          emailContent: this.emailContent
        }
        await this.$store.dispatch("saveReceiptCommsConfig", payload)
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "Receipt Comms configuration saved successfully",
          type: "success"
        });
      } catch (e) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: e,
          type: "error"
        });
      } finally {
        this.$store.commit("HIDE_PROGRESS")
        scrollTo(0,0)
      }
    },
    loadView() {
      this.smsContent = this.$store.state.receiptCommsConfig.smsContent;
      this.emailFrom = this.$store.state.receiptCommsConfig.emailFrom;
      this.emailSubject = this.$store.state.receiptCommsConfig.emailSubject;
      this.emailContent = this.$store.state.receiptCommsConfig.emailContent;
    },
    copyDict(myValue) {
      navigator.clipboard.writeText(myValue);
    },
    validateEmailContent(value) {
      if (value && value.length > 4000) {
        return 'Email Content maximum html characters are 4000'
      }
      return true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.$store.dispatch("fetchPaymentPortalGlobalConfig");
        await vm.$store.dispatch("fetchReceiptCommsConfig");
        vm.loadView();
      } catch (e) {
        vm.$store.commit("SHOW_NOTIFICATION", {
          type: "error",
          message: `Error loading Receipt Comms config; ${e}`
        });
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  }
};
</script>
<style lang="scss">
.dict-wrapper {
  margin-top: 0.5rem;
  border-width: 1px;
  padding: 0.5rem 0.25rem 0.5rem 0.5rem;
  border-radius: 0.25rem;
  background-color: rgb(249,250,251);
  border-color: rgb(229, 231, 235);
  border-style: solid;
}

.dict-title {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(107,114,128);
}

.dict-body {
  display: flex;
  margin-top: 0.25rem;
}

.dict-item {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-width: 1px;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgb(243,244,246);
  color: rgb(107,114,128);
  border-color: rgb(229, 231, 235);
  border-style: solid;
  margin-left: 0.5rem;
}

.dict-item:first-child {
  margin-left: 0;
}

.dict-item:hover {
  border-color: rgb(107,114,128);

}
.dict-item:active {
  border-color: rgb(229, 231, 235);
  background-color: rgb(229, 231, 235);
}
</style>