var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('h1',{staticClass:"title"},[_vm._v("Hosted Payment Page")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Document Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.documentTitle),expression:"documentTitle"}],staticClass:"input",attrs:{"name":"Document Title"},domProps:{"value":(_vm.documentTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.documentTitle=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Header")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.header),expression:"header"}],staticClass:"input",attrs:{"name":"Header"},domProps:{"value":(_vm.header)},on:{"input":function($event){if($event.target.composing){ return; }_vm.header=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div"}},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Header Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.headerColor),expression:"headerColor"}],staticClass:"input",attrs:{"type":"color","name":"Header Color"},domProps:{"value":(_vm.headerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.headerColor=$event.target.value}}})])]),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Logo URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.logoURL),expression:"logoURL"}],staticClass:"input",attrs:{"name":"Logo URL"},domProps:{"value":(_vm.logoURL)},on:{"input":function($event){if($event.target.composing){ return; }_vm.logoURL=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('file-upload',{attrs:{"inputName":"file_logoURL","inputRef":"file_logoURL"},on:{"fileUploaded":_vm.updateLogoURL}}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Logo HREF")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.logoHREF),expression:"logoHREF"}],staticClass:"input",attrs:{"name":"Logo HREF"},domProps:{"value":(_vm.logoHREF)},on:{"input":function($event){if($event.target.composing){ return; }_vm.logoHREF=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Secondary Banner")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondaryBanner),expression:"secondaryBanner"}],staticClass:"input",attrs:{"name":"Secondary Banner"},domProps:{"value":(_vm.secondaryBanner)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secondaryBanner=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div"}},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Secondary Banner Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secondaryBannerColor),expression:"secondaryBannerColor"}],staticClass:"input",attrs:{"type":"color","name":"Secondary Banner Color"},domProps:{"value":(_vm.secondaryBannerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secondaryBannerColor=$event.target.value}}})])]),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Footer")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.footer),expression:"footer"}],staticClass:"input",attrs:{"name":"Footer"},domProps:{"value":(_vm.footer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.footer=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div"}},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Footer Color")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.footerColor),expression:"footerColor"}],staticClass:"input",attrs:{"type":"color","name":"Footer Color"},domProps:{"value":(_vm.footerColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.footerColor=$event.target.value}}})])]),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Footer 2")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.footer2),expression:"footer2"}],staticClass:"input",attrs:{"name":"Footer 2"},domProps:{"value":(_vm.footer2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.footer2=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Favicon URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.faviconURL),expression:"faviconURL"}],staticClass:"input",attrs:{"name":"Favicon URL"},domProps:{"value":(_vm.faviconURL)},on:{"input":function($event){if($event.target.composing){ return; }_vm.faviconURL=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('file-upload',{attrs:{"inputName":"file_faviconURL","inputRef":"file_faviconURL"},on:{"fileUploaded":_vm.updateFaviconURL}}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("General Contact Message For Help")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.generalContactMessage),expression:"generalContactMessage"}],staticClass:"textarea",attrs:{"name":"General Contact Message"},domProps:{"value":(_vm.generalContactMessage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.generalContactMessage=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Fee Annotation")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.feeAnnotation),expression:"feeAnnotation"}],staticClass:"input",attrs:{"name":"Fee Annotation"},domProps:{"value":(_vm.feeAnnotation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.feeAnnotation=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Success Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.successMessage),expression:"successMessage"}],staticClass:"textarea",attrs:{"name":"Success Message"},domProps:{"value":(_vm.successMessage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.successMessage=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.savePaymentPortalGlobalConfig}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])]),_c('progress-bar')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }