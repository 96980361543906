var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('h1',{staticClass:"title"},[_vm._v("Secure Link")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.url),expression:"url"}],staticClass:"input",attrs:{"name":"URL"},domProps:{"value":(_vm.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.url=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("SMS Content")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.smsContent),expression:"smsContent"}],staticClass:"input",attrs:{"name":"SMS Content"},domProps:{"value":(_vm.smsContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.smsContent=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Email Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailSubject),expression:"emailSubject"}],staticClass:"input",attrs:{"name":"Email Subject"},domProps:{"value":(_vm.emailSubject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailSubject=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Email Content")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailContent),expression:"emailContent"}],staticClass:"textarea",attrs:{"name":"Email Content"},domProps:{"value":(_vm.emailContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailContent=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.saveSecureLinkConfig}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])]),_c('progress-bar')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }